body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  text-align: center;
  max-width: 90%;
}

#info-block section {
  border: 1px solid steelblue;
  border-radius: 10px;
  margin: 10px;
  padding: 0 10px;
}

.file-marker > div {
  padding: 0 3px;
  margin-top: -1.8em;
  text-align: left;
}

.box-title {
  background: white none repeat scroll 0 0;
  display: inline-block;
  padding: 0 2px;
  font-size: 36px;
  font-weight: bold;
  color: steelblue;
}

.card {
  width: 100%;
  height: 25vh;
  margin-bottom: 10px;
  border: 1px solid rgba(100, 100, 100);
  border-radius: 20px;
  box-shadow: 10px 5px 5px #aaaaaa;
  position: relative;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  transition: all 1s;
}

.card:hover {
  transform: scale(1.05);
}

.image-div {
  border-radius: 20px 20px 0 0;
  height: 20vh;
  object-fit: fill;
}

.app-title {
  font-size: 3rem;
  margin: 40px auto;
  font-weight: bold;
}
.back-grad {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15vh;
  border-radius: 20px;
  background-image: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
}

.title-text {
  font-size: 18px;
  position: absolute;
  left: 10px;
  bottom: 0;
  color: #ffffff;
  font-weight: 600;
}

.description-text {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-align: justify;
  font-size: 16px;
}
.box-contents-bottom {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

@media screen and (max-width: 480px) {
  .app-title {
    font-size: 1.5rem;
    margin: 20px auto;
    font-weight: bold;
  }
  .box-contents-bottom {
    grid-template-columns: repeat(2, 1fr);
  }

  .description-text {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: justify;
    font-size: 14px;
    line-height: 1.1;
  }

  .file-marker > div {
    padding: 0 3px;
    margin-top: -1em;
    text-align: center;
  }

  .box-title {
    background: white none repeat scroll 0 0;
    display: inline-block;
    padding: 0 2px;
    font-size: 20px;
    font-weight: bold;
    color: steelblue;
  }
}

/*@media screen and (max-width: 480px) {*/
/*    .image-div {*/
/*        height: 100%;*/
/*        object-fit: contain;*/
/*        border-radius: 20px 20px 0 0;*/
/*    }*/

/*    .title-text {*/
/*        font-size: 16px;*/
/*    }*/
/*}*/

/*@media screen and (min-width: 481px) and (max-width: 1280px) {*/
/*    .image-div {*/
/*        height: 190px;*/
/*        object-fit: contain;*/
/*        border-radius: 20px 20px 0 0;*/
/*    }*/

/*    .title-text {*/
/*        font-size: 18px;*/
/*    }*/
/*}*/

/*@media screen and (min-width: 1281px) and (max-width: 2000px) {*/
/*    .image-div {*/
/*        height: 200px;*/
/*        object-fit: contain;*/
/*        border-radius: 20px 20px 0 0;*/
/*    }*/

/*    .title-text {*/
/*        font-size: 20px;*/
/*    }*/
/*}*/
